import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  label: string;
  children: any;
  saveButton?: { label: string, onClick: () => void, icon: React.ReactNode };
}

export const ModalInformation: React.FC<ModalProps> = ({ label, isOpen, onClose, children, saveButton}) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(isOpen);
  
    useEffect(() => {
      setIsModalOpen(isOpen);
    }, [isOpen]);
  
    return (
      <>
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50 min-h-[50%]">
            <div className="fixed inset-0 bg-black opacity-50"></div>
  
            <div
              className="relative flex md:w-4/6 w-5/6 min-h-2/6 flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
              <div
                className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4">
                <h5
                  className="text-xl font-medium leading-normal text-neutral-800">
                  {label}
                </h5>
  
                <button
                  type="button"
                  className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                  data-te-modal-dismiss
                  aria-label="Close">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    onClick={onClose}
                    className="h-6 w-6">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="relative flex-auto p-4" data-te-modal-body-ref>
                {children}
              </div>
              <div
                className="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4">
                <Button isTerciary onClick={onClose} classNames='text-black'>
                  <span className='flex items-center font-semibold pr-3'>
                    <div className='w-8 p-1 aspect-square mr-2'><XMarkIcon /></div>
                    {t('CLOSE')}
                  </span>
                </Button>
                { saveButton && <Button onClick={saveButton.onClick} classNames="ml-2">
                    <span className="flex items-center font-semibold pr-3">
                      <div className="w-8 p-1 aspect-square mr-2">{saveButton.icon ?? <CheckIcon />}</div>
                      {saveButton.label ?? t('SAVE')}
                    </span>
                  </Button>
                }
              </div>
            </div>
          </div>
        )}
      </>
    );
};