import { createSlice } from "@reduxjs/toolkit";
import { Device } from "twilio-client";
import { TwilioDeviceState } from "../../enums/TwilioDeviceState";
import { PhoneBurnerObject, TwilioCall } from "../../models/TwilioCall";
import { IncomingMessage, TwilioConversation } from "../../models/TwilioConversation";
import { callNextPhoneInBurner, cleanPhoneBurner, createConversation, fetchCalls, fetchChatToken, fetchConversationMessages, fetchConversations, fetchPhoneContacts, fetchVoiceToken, handleIncomingMessage, health, joinConference, markMessageAsRead, removeCurrentConversation, removePhoneFromBurner, sendMessage, setPhoneBurner, setStateLeadCreated, setTwilioDevice, setTwilioDeviceState, startClientConference } from "./actions";

interface TwilioState {
    isLoading: boolean
    voiceToken: string
    chatToken: string
    error: string | null
    conversations: TwilioConversation[]
    calls: TwilioCall[]
    currentConversation: TwilioConversation | null,
    unreadMessages?: IncomingMessage[],
    twilioDeviceState: TwilioDeviceState,
    device: Device | null,
    areTwilioCredentialsValid?: boolean,
    phoneContacts?: any,
    clientConferenceId?: string
    phoneBurner?: PhoneBurnerObject,
    isLeadCreated?: boolean
}

const initialState: TwilioState = {
    isLoading: false,
    voiceToken: "",
    chatToken: "",
    error: null,
    conversations: [],
    calls: [],
    currentConversation: null,
    unreadMessages: [],
    twilioDeviceState: TwilioDeviceState.OFFLINE,
    device: null,
    clientConferenceId: "",
}

// slice
const twilioSlice = createSlice({
    name: "twilio",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchVoiceToken.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchVoiceToken.fulfilled, (state, action: any) => {
                state.isLoading = false;
                state.voiceToken = action.payload;
            })
            .addCase(fetchVoiceToken.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(fetchChatToken.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchChatToken.fulfilled, (state, action: any) => {
                state.isLoading = false;
                state.chatToken = action.payload;
            })
            .addCase(fetchChatToken.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(fetchPhoneContacts.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchPhoneContacts.fulfilled, (state, action: any) => {
                state.isLoading = false;
                state.phoneContacts = action.payload;
            })
            .addCase(fetchPhoneContacts.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(fetchConversations.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchConversations.fulfilled, (state, action: any) => {
                state.isLoading = false;
                state.conversations = action.payload;
            })
            .addCase(fetchConversations.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
                state.conversations = [];
            })
            .addCase(fetchCalls.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchCalls.fulfilled, (state, action: any) => {
                state.isLoading = false;
                state.calls = action.payload;
            })
            .addCase(fetchCalls.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
                state.calls = [];
            })
            .addCase(createConversation.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createConversation.fulfilled, (state, action: any) => {
                state.isLoading = false;
                state.conversations = state.conversations.concat(action.payload)
                state.currentConversation = action.payload;
            })
            .addCase(createConversation.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(sendMessage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(sendMessage.fulfilled, (state, action: any) => {
                state.isLoading = false;
                if (state.currentConversation) {
                    const updatedConversation = state.currentConversation;
                    const updatedConversationMessages = state.currentConversation?.messages?.concat(action.payload) ?? [action.payload];
                    updatedConversation.messages = updatedConversationMessages;
                    state.currentConversation = updatedConversation;
                    const currentAllConversations = state.conversations;
                    const newMessageConversation = state.conversations?.find(x => x.sid === action.payload.conversationSid);
                    if (newMessageConversation) {
                        const index = state.conversations?.indexOf(newMessageConversation);
                        if (currentAllConversations && index !== -1) {
                            currentAllConversations[index] = { ...newMessageConversation, messages: updatedConversationMessages, lastMessage: action.payload.body ?? "", dateUpdated: action.payload.dateCreated };
                            state.conversations = currentAllConversations;
                        }
                    }
                }
            })
            .addCase(sendMessage.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(handleIncomingMessage.fulfilled, (state, action: any) => {
                const currentAllConversations = state.conversations;
                let newMessageConversation = state.conversations?.find(x => x.sid === action.payload.conversationSid);
                if (newMessageConversation) {
                    const index = state.conversations?.indexOf(newMessageConversation);
                    if (currentAllConversations && index !== -1) {
                        currentAllConversations[index] = { ...newMessageConversation, newMessage: true, lastMessage: action.payload.text ?? "", dateUpdated: action.payload.date };
                        state.conversations = currentAllConversations;
                    }
                } else {
                    newMessageConversation = state.conversations?.find(x => x.sid === action.payload.conversationSid);
                    if (newMessageConversation) {
                        const index = state.conversations?.indexOf(newMessageConversation);
                        if (currentAllConversations && index !== -1) {
                            currentAllConversations[index] = { ...newMessageConversation, newMessage: true, lastMessage: action.payload.text ?? "", dateUpdated: action.payload.date };
                            state.conversations = currentAllConversations;
                        }
                    } else {
                        state.conversations = state.conversations.concat({
                            friendlyName: action.payload.from,
                            participant: action.payload.from,
                            dateUpdated: action.payload.date,
                            sid: action.payload.conversationSid,
                            lastMessage: action.payload.text
                        } as TwilioConversation);
                    }
                }
                if (state.currentConversation?.sid === action.payload.conversationSid) {
                    const conversation = state.currentConversation;
                    if (conversation) {
                        conversation.messages = conversation.messages?.concat({
                            body: action.payload.text,
                            dateCreated: action.payload.date,
                            author: action.payload.from,
                            sid: action.payload.conversationSid
                        });
                        state.currentConversation = conversation;
                    }
                } else {
                    state.unreadMessages = state.unreadMessages?.concat(action.payload);
                }
            })
            .addCase(markMessageAsRead.fulfilled, (state, action: any) => {
                state.unreadMessages = state.unreadMessages?.filter(x => x.conversationSid !== action.payload.conversationSid);
            })
            .addCase(fetchConversationMessages.fulfilled, (state, action: any) => {
                state.unreadMessages = state.unreadMessages?.filter(x => x.conversationSid !== action.payload.sid);
                const conversation = state.conversations.find(x => x.sid === action.payload.sid);
                if (conversation) {
                    conversation.messages = action.payload.messages;
                    state.currentConversation = conversation;
                }
            })
            .addCase(setTwilioDeviceState.fulfilled, (state, action: any) => {
                state.twilioDeviceState = action.payload;
            })
            .addCase(setTwilioDevice.fulfilled, (state, action: any) => {
                state.device = action.payload;
            })
            .addCase(health.fulfilled, (state, action: any) => {
                state.areTwilioCredentialsValid = action.payload;
            })
            .addCase(removeCurrentConversation.fulfilled, (state, action: any) => {
                state.currentConversation = null;
            })
            .addCase(startClientConference.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(startClientConference.fulfilled, (state, action: any) => {
                state.isLoading = false;
                state.clientConferenceId = action.payload.client_conference_id;
            })
            .addCase(startClientConference.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(joinConference.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(joinConference.fulfilled, (state, action: any) => {
                state.clientConferenceId = '';
                state.isLoading = false;
            })
            .addCase(joinConference.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(setPhoneBurner.fulfilled, (state, action: any) => {
                state.phoneBurner = {
                    remainingList: action.payload
                }
            })
            .addCase(callNextPhoneInBurner.fulfilled, (state, action: any) => {
                if (state.phoneBurner) {
                    state.phoneBurner = {
                        currentCall: state.phoneBurner.remainingList[0],
                        remainingList: state.phoneBurner.remainingList.slice(1)
                    }
                }
            })
            .addCase(removePhoneFromBurner.fulfilled, (state, action: any) => {
                if (state.phoneBurner) {
                    state.phoneBurner = {
                        currentCall: state.phoneBurner.currentCall,
                        remainingList: state.phoneBurner.remainingList.filter((_, index) => index !== action.payload)
                    }
                }
            })
            .addCase(cleanPhoneBurner.fulfilled, (state, action: any) => {
                if (state.phoneBurner) {
                    state.phoneBurner = {
                        currentCall: undefined,
                        remainingList: []
                    }
                }
            })
            .addCase(setStateLeadCreated.fulfilled, (state, action: any) => {
                state.isLeadCreated = action.payload;
            })
    },
});

export default twilioSlice.reducer;