import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Agency, BillingPeriod } from "../../models/Agency";
import { createAgencyBillingPeriod, createAgencyTwilioConfig, 
    fetchAgencyBillingPeriods, fetchAgencyTwilioConfig, 
    fetchAgencyTwilioConfigs, setCurrentAgency, 
    updateAgency, updateAgencyTwilioConfig, endAgencyBillingPeriod, 
    fetchAgencyUserCommissionRules} from "./action";

interface AgencyState {
    currentAgency: Agency | null,
    isLoading: boolean,
    error: string | null,
    twilioConfigs: any[],
    twilioConfig: any | null,
    billingPeriods: BillingPeriod[]
}

const initialState: AgencyState = {
    currentAgency: null,
    isLoading: false,
    error: null,
    twilioConfigs: [],
    twilioConfig: null,
    billingPeriods: []
}

// slice
const agenciesSlice = createSlice({
    name: "agencies",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateAgency.pending, (state) => {
                state.isLoading = true;
                state.error = null
            })
            .addCase(updateAgency.fulfilled, (state, action: PayloadAction<Agency>) => {
                state.currentAgency = action.payload;
                state.isLoading = false;
                state.error = null;
            })
            .addCase(updateAgency.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(setCurrentAgency.fulfilled, (state, action: PayloadAction<Agency>) => {
                state.isLoading = false;
                state.currentAgency = action.payload;
            })
            .addCase(fetchAgencyTwilioConfigs.pending, (state) => {
                state.isLoading = true;
                state.error = null
            })
            .addCase(fetchAgencyTwilioConfigs.fulfilled, (state, action: PayloadAction<any[]>) => {
                state.isLoading = false;
                state.twilioConfigs = action.payload;
            })
            .addCase(fetchAgencyTwilioConfigs.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(createAgencyTwilioConfig.pending, (state) => {
                state.isLoading = true;
                state.error = null
            })
            .addCase(createAgencyTwilioConfig.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.twilioConfigs = state.twilioConfigs.concat(action.payload);
            })
            .addCase(createAgencyTwilioConfig.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(fetchAgencyTwilioConfig.pending, (state) => {
                state.error = null
            })
            .addCase(fetchAgencyTwilioConfig.fulfilled, (state, action: PayloadAction<any[]>) => {
                state.twilioConfig = action.payload;
            })
            .addCase(fetchAgencyTwilioConfig.rejected, (state, action: any) => {
                state.error = action.payload;
            })
            .addCase(updateAgencyTwilioConfig.pending, (state) => {
                state.isLoading = true;
                state.error = null
            })
            .addCase(updateAgencyTwilioConfig.fulfilled, (state, action: PayloadAction<any>) => {
                const updatedPhoneConfig = action.payload;
                const updatedPhones = state.twilioConfigs.map((phone) => {
                    return phone.id === updatedPhoneConfig.id ? updatedPhoneConfig : phone
                });
                state.twilioConfig = action.payload
                state.twilioConfigs = updatedPhones;
                state.isLoading = false;
            })
            .addCase(updateAgencyTwilioConfig.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(fetchAgencyBillingPeriods.pending, (state) => {
                state.isLoading = true;
                state.error = null
            })
            .addCase(fetchAgencyBillingPeriods.fulfilled, (state, action: PayloadAction<BillingPeriod[]>) => {
                state.isLoading = false;
                state.billingPeriods = action.payload;
            })
            .addCase(fetchAgencyBillingPeriods.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(createAgencyBillingPeriod.pending, (state) => {
                state.isLoading = true;
                state.error = null
            })
            .addCase(createAgencyBillingPeriod.fulfilled, (state, action: PayloadAction<BillingPeriod>) => {
                state.isLoading = false;
                state.billingPeriods.push(action.payload);
            })
            .addCase(createAgencyBillingPeriod.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(endAgencyBillingPeriod.pending, (state) => {
                state.isLoading = true;
                state.error = null
            })
            .addCase(endAgencyBillingPeriod.fulfilled, (state, action: PayloadAction<BillingPeriod[]>) => {
                state.isLoading = false;
                state.billingPeriods = action.payload;
            })
            .addCase(endAgencyBillingPeriod.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    },
});

export default agenciesSlice.reducer;