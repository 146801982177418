import i18next from "i18next";

export enum CarrierEnum {
    AETNA = 1,
    AMBETTER = 2,
    CIGNA = 3,
    OSCAR = 4,
    KAISER_PERMANENTE = 5,
    CHRISTUS = 6,
    SCOTT_AND_WHITE = 7,
    BLUE_CROSS_OK = 8,
    ANTHEM = 9,
    CARESOURCE = 10,
    MEDICA = 11,
    MOLINA = 12,
    UNITED_HEALTH_CARE = 13,
    BCBS = 14,
    SELECT_HEALTH = 15,
    COMMON_GROUND = 16,
    ALLIANT = 17,
    FLORIDA_BLUE = 18,
    AMERIHEALTH = 19,
    AMERICAN_HEALTH = 20,
    MYBLUE = 21,
    WELLMARK = 22
}


export const CarrierLabel = (key: string) => ({
    [CarrierEnum.AETNA]: i18next.t('AETNA'),
    [CarrierEnum.AMBETTER]: i18next.t('AMBETTER'),
    [CarrierEnum.CIGNA]: i18next.t('CIGNA'),
    [CarrierEnum.OSCAR]: i18next.t('OSCAR'),
    [CarrierEnum.KAISER_PERMANENTE]: i18next.t('KAISER_PERMANENTE'),
    [CarrierEnum.CHRISTUS]: i18next.t('CHRISTUS'),
    [CarrierEnum.SCOTT_AND_WHITE]: i18next.t('SCOTT_AND_WHITE'),
    [CarrierEnum.BLUE_CROSS_OK]: i18next.t('BLUE_CROSS_OK'),
    [CarrierEnum.ANTHEM]: i18next.t('ANTHEM'),
    [CarrierEnum.CARESOURCE]: i18next.t('CARESOURCE'),
    [CarrierEnum.MEDICA]: i18next.t('MEDICA'),
    [CarrierEnum.MOLINA]: i18next.t('MOLINA'),
    [CarrierEnum.UNITED_HEALTH_CARE]: i18next.t('UNITED_HEALTH_CARE'),
    [CarrierEnum.BCBS]: i18next.t('BCBS'),
    [CarrierEnum.SELECT_HEALTH]: i18next.t('SELECT_HEALTH'),
    [CarrierEnum.COMMON_GROUND]: i18next.t('COMMON_GROUND'),
    [CarrierEnum.ALLIANT]: i18next.t('ALLIANT'),
    [CarrierEnum.FLORIDA_BLUE]: i18next.t('FLORIDA_BLUE'),
    [CarrierEnum.AMERIHEALTH]: i18next.t('AMERIHEALTH'),
    [CarrierEnum.AMERICAN_HEALTH]: i18next.t('AMERICAN_HEALTH'),
    [CarrierEnum.MYBLUE]: i18next.t('MYBLUE'),
    [CarrierEnum.WELLMARK]: i18next.t('WELLMARK'),
})[key];

export const getCarriers = () =>
    Object.values(CarrierEnum).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        let a =  {
            key: element,
            value: value,
            label: CarrierLabel(value) ?? ""
        }
        return a
    });