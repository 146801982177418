import { ClipboardDocumentListIcon, PaperAirplaneIcon, PencilIcon } from "@heroicons/react/24/outline";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ReactiveFormRadioButtonSelect } from "../../components/form/ReactiveFormRadioButtonSelect";
import Button from "../../components/Button";
import FilteredTable from "../../components/FilteredTable";
import { ReactiveFormInput } from "../../components/form/ReactiveFormInput";
import { LoadingMask } from "../../components/LoadingMask";
import { Modal } from "../../components/Modal";
import { Toast } from "../../components/Toast";
import { RoleEnum } from "../../enums/Role";
import { EditPolicy, Policy } from "../../models/Policies";
import { fetchPolicies, pastPage, updatePolicy } from "../../state/policies/actions";
import { AppDispatch } from "../../state/store";
import { longDateFormat, shortDateFormat, shortLocalDateFormat } from "../../utils/functions";
import { PolicyFilterModal } from "./PolicyFilterModal";
import { PolicySellsReportPath } from "../../constants/Routes";
import { ReactiveFormSelect } from "../../components/form/ReactiveFormSelect";
import { getPolicyStates, PolicyStatusLabel } from "../../enums/PolicyState";

interface Props {
}

export const Policies = (props: Props) => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const pagePolicies = useSelector((state: any) => state.policies.pagePolicies);
    const paginatedOptions = useSelector((state: any) => state.policies.paginatedOptions);
    const isLoading = useSelector((state: any) => state.policies.isLoadingPolicies);
    const currentUser = useSelector((state: any) => state.users.currentUser);
    const [isFilterModalOpen, setFilterModalOpen] = useState<boolean>(false)
    const [isUpdatePolicyModalOpen, setIsUpdatePolicyModalOpen] = useState(false);
    const [selectedPolicy, setSelectedPolicy] = useState<Policy|null>(null)
    const dispatch = useDispatch<AppDispatch>();
    const [showUpdateError,setShowUpdateError] = useState(false);
    const [isPolicyUpdateLoading, setIsPolicyUpdateLoading] = useState(false);
    const errorUpdate = useSelector((state: any) => state.policies.errorUpdate);

    useEffect(() => {
        if(showUpdateError){
            Toast(t(errorUpdate.reason) ?? t("SOMETHING_WENT_WRONG"), errorUpdate.cause_info);
        }
    },[showUpdateError, errorUpdate, t]);

    const columns = useMemo(() => {
        const showColumns = [
            {
                Header: t('POLICY_NUMBER'),
                accessor: "number",
            },
            {
                Header: t('CARRIER'),
                Cell: (row: any) => t(`${row.row.original.carrier}`),
            },
            {
                Header: t('CLIENT'),
                accessor: "client",
            },
            {
                Header: t('EFFECTIVE_START_DATE'),
                Cell: (row: any) => shortDateFormat(`${row.row.original.effective_start_date}`),
            },
            {
                Header: t('STATUS'),
                Cell: (row: any) => row.row.original.policy_state != null ? PolicyStatusLabel(row.row.original.policy_state) : "-",
            },
            {
                Header: t("FREE_PLAN"),
                Cell: (row: any) => `${Number(row.row.original.free_plan) === 1 ? t("YES") : t("NO")}`,
            },
        ];

        const actions = {
            Header: " ",
            Cell: (row: any) => {
                return <div className="flex flex-row justify-center divide-x">
                    <Button
                        isTerciary
                        tooltip={t("UPDATE_POLICY")}
                        iconOnly
                        onClick={() => openUpdatePolicyModal(row.row.original)} classNames="mr-2"><PencilIcon className="h-5 aspect-square stroke-blue" />
                    </Button>
                </div>
            }
        };

        if (currentUser?.role_id !== RoleEnum.AGENT) {
            return [...showColumns, {
                Header: t('AGENT'),
                Cell: (row: any) => `${row.row.original.agent ?? ""}`,
            }, {
                Header: t('LAST_UPDATE'),
                Cell: (row: any) => row.row.original.last_update != null ? longDateFormat(`${row.row.original.last_update}`) : "-",
            }, actions]
        }
        return [...showColumns, {
            Header: t('LAST_UPDATE'),
            Cell: (row: any) => row.row.original.last_update != null ? longDateFormat(`${row.row.original.last_update}`) : "-",
        }, actions];
    }, [t, currentUser]);

    const getPaginationOptions = useMemo(() => {
        return {
            page: paginatedOptions.page,
            pageSize: paginatedOptions.pageSize,
            totalPages: paginatedOptions.totalPages,
            totalCount: paginatedOptions.totalCount,
            setPage: (value: number) => {
                value < paginatedOptions.page
                 ? dispatch(pastPage())
                 : dispatch(fetchPolicies({page: value, pageSize: paginatedOptions.pageSize}));
            },
            setPageSize: (value: number) => dispatch(fetchPolicies({page: paginatedOptions.page, pageSize: value})),
        }
    }, [dispatch, paginatedOptions]);

    const updateMethods = useForm<any>({
        defaultValues: {}
    });
    const { handleSubmit, control, setValue } = updateMethods;

    const openUpdatePolicyModal = (policy: Policy) => {
        setValue("number", policy.number);
        setValue("carrier", t(`${policy.carrier}`) );
        setValue("client", policy.client);
        setValue("agent", policy.agent);
        setValue("effective_start_date", policy.effective_start_date);
        setValue("effective_end_date", policy.effective_end_date);
        setValue("agent_commission_rule", policy.agent_commission_rule_id);
        setValue("members_insured", policy.members_insured);
        setValue("free_plan", policy.free_plan);
        setValue("policy_state", policy.policy_state)

        setSelectedPolicy(policy)
        setIsUpdatePolicyModalOpen(true);
    };

    const closeUpdatePolicyModal = () => {
        setIsUpdatePolicyModalOpen(false);
        setSelectedPolicy(null);
    };

    const updatePolicyModal = () => {
        return <Modal isOpen={isUpdatePolicyModalOpen} onClose={closeUpdatePolicyModal} label={t("UPDATE_POLICY")} saveButton={{ label: t("SAVE"), icon: <PaperAirplaneIcon />, onClick: handleSubmit(onUpdatePolicy) }}>
          {isPolicyUpdateLoading && <LoadingMask />}
          <div className='w-full flex flex-wrap'>
            <ReactiveFormInput
                control={control}
                className='md:w-1/3'
                label={t("POLICY_NUMBER")}
                name={`number`}
            />
            <ReactiveFormInput
                control={control}
                className='md:w-1/3'
                label={t("CARRIER")}
                name={`carrier`}
                isDisabled={true}
            />
            <ReactiveFormInput
                control={control}
                className='md:w-1/3'
                label={t("CLIENT")}
                name={`client`}
                isDisabled={true}
            />
            {
                currentUser?.role_id !== RoleEnum.AGENT &&
                <ReactiveFormInput
                    control={control}
                    className='md:w-1/3'
                    label={t("AGENT")}
                    name={`agent`}
                    isDisabled={true}
                />
            }
            <ReactiveFormInput
              control={control}
              type='date'
              className='md:w-1/3'
              isDisabled={true}
              label={t("EFFECTIVE_START_DATE")}
              name={`effective_start_date`}
            />
            <ReactiveFormInput
              control={control}
              type='date'
              className='md:w-1/3'
              label={t("EFFECTIVE_END_DATE")}
              name={`effective_end_date`}
            />
            <ReactiveFormInput
                control={control}
                className='md:w-1/3'
                label={t("MEMBERS_INSURED")}
                name={`members_insured`}
                type="number"
            />
            <ReactiveFormSelect
                className="md:w-1/3"
                name={`policy_state`}
                label={t("STATUS")}
                options={getPolicyStates()}
                control={control} 
            />
            <ReactiveFormRadioButtonSelect
                className='md:w-1/3'
                control={control}
                name="free_plan"
                label={t("FREE_PLAN")}
                options={[{ label: t("YES"), value: "1" }, { label: t("NO"), value: "0" }]}
            />
          </div>
        </Modal>
    };

    const onUpdatePolicy = (data: any) => {

        if(checkPolicyMembers(data)) {
            Toast(t("POLICY_MEMBERS_ERROR"), t("POLICY_MEMBERS_MESSAGE"));
        } else {
            setIsPolicyUpdateLoading(true);

            const editPolicy: EditPolicy = {
                id: selectedPolicy?.id,
                number: data.number,
                effective_end_date: data.effective_end_date,
                members_insured: data.members_insured,
                free_plan: data.free_plan,
                policy_state: data.policy_state
            };
        
            dispatch(updatePolicy({ policy: editPolicy })).then((e) => {
                if (e.type === "policies/update/rejected") {
                    setShowUpdateError(true);
                    setIsPolicyUpdateLoading(false);
                } else {
                    toast(t("SAVED_SUCCESFULLY"));
                    setIsPolicyUpdateLoading(false);
                    closeUpdatePolicyModal();
                    //navigate(ClientsPath)
                }
            })
        }
    }

    const checkPolicyMembers = (data: any) => {
        let policiesMembers = 0;
    
        if(data.members_insured != undefined) {
            if(data.members_insured <= 0) {
              policiesMembers++;
            }
        } else {
            policiesMembers++;
        }
    
        return policiesMembers > 0;
    }
    
    return (<>
        {isLoading && <LoadingMask />}
        {updatePolicyModal()}
        <ToastContainer progressStyle={{ "background": "#D4AF37" }} />
        <FilteredTable
            columns={columns} 
            data={pagePolicies}
            isPaginated
            paginationOptions={getPaginationOptions}
            hasSearchBar={false}
            secondaryActionButton={
                currentUser?.role_id == RoleEnum.ADMIN || currentUser?.role_id == RoleEnum.SUPER_ADMIN
                ?   <Button onClick={() => navigate(PolicySellsReportPath)}>
                        <span className='flex items-center font-semibold pr-3'>
                            <div className='w-8 p-1 aspect-square mr-2'><ClipboardDocumentListIcon /></div>
                            {t('POLICY_SALES_REPORT')}
                        </span>
                    </Button> 
                :   <div></div>
            }
            /*buttonAction={<Button onClick={() => setFilterModalOpen(true)} iconOnly classNames="w-9 p-1 aspect-square mr-4 ">
                <FunnelIcon className=""></FunnelIcon></Button>}
                */
        />
        <PolicyFilterModal isFilterModalOpen={isFilterModalOpen} setFilterModalOpen={() => setFilterModalOpen(false)} />
    </>
    );
}