import { UserPlusIcon } from '@heroicons/react/24/outline';
import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import states from 'states-us';
import Button from '../../components/Button';
import { LoadingMask } from '../../components/LoadingMask';
import { Toast } from '../../components/Toast';
import { Form } from '../../components/form/Form';
import { ReactiveFormInput } from '../../components/form/ReactiveFormInput';
import { ReactiveFormPhoneInput } from '../../components/form/ReactiveFormPhoneInput';
import { ReactiveFormRadioButtonText } from '../../components/form/ReactiveFormRadioButtonText';
import { ReactiveFormSelect } from '../../components/form/ReactiveFormSelect';
import { LeadsPath } from '../../constants/Routes';
import { getLeadStatuses } from '../../enums/LeadStatus';
import { getSources } from '../../enums/Source';
import { NewLead } from '../../models/Lead';
import { createLead, startLeadAutomation } from '../../state/leads/actions';
import { AppDispatch } from '../../state/store';
import { fetchConversations } from '../../state/twilio/actions';

export const CreateLead = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const isLoading = useSelector((state: any) => state.leads.isLoading);
    const error = useSelector((state: any) => state.leads.error);
    const automations = useSelector((state: any) => state.automations.automations);
    const [showAutomation, setShowAutomation] = useState(false);
    const methods = useForm<NewLead>();
    const { handleSubmit, control } = methods;
    const [showError,setShowError] = useState(false);
    const currentUser = useSelector((state: any) => state.users.currentUser);

    useEffect(() => {
        if(showError){
            Toast(t(error.reason) ?? t("SOMETHING_WENT_WRONG"), error.cause_info)
        }
    },[showError, error, t]);

    const getStates = useMemo(() => {
        return states.map((state: { name: any; abbreviation: any; }) => {
            return {
                label: state.name,
                value: state.abbreviation,
                key: state.abbreviation
            }
        })
    }, []);

    const startAutomationClaimed = useWatch({
        control,
        name: "start_automation", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
        defaultValue: "No", // default value before the render
    })

    useEffect(() => {
        if (startAutomationClaimed === "Yes") {
            setShowAutomation(true)
        }
        else {
            setShowAutomation(false)
        }
    }, [startAutomationClaimed]);

    const getAutomations = useMemo(() => {
        return automations.map((a: any) => {
            return {
                label: `${a.name}`,
                value: `${a.id}`,
                key: a.id
            }
        })
    }, [automations]);
      
    const onSubmit: SubmitHandler<NewLead> = async (data: NewLead) => {
        const updatedData = data;
        if (data.referred_amount) {
            updatedData.referred_amount = +data.referred_amount;
        }
        if (data.source_id) {
            updatedData.source_id = +data.source_id;
        }
        if (data.status_id) {
            updatedData.status_id = +data.status_id;
        }

        dispatch(createLead(updatedData)).then((e) => {
            if (e.type === "leads/createLead/rejected") {
                setShowError(true);
            } else {
                if(updatedData.start_automation === undefined || updatedData.start_automation === 'No') {
                    toast(t("SAVED_SUCCESFULLY"));
                    navigate(LeadsPath)
                } else {
                    const lead = e.payload as any;
                    dispatch(startLeadAutomation({ phone: lead.phone, automationId: data.automation_id!})).then((e) => {
                        if (e.type === "leads/startAutomation/rejected") {
                            setShowError(true);
                        } else {
                            toast(t("SAVED_SUCCESFULLY"));
                            dispatch(fetchConversations(currentUser.main_twilio_number?.twilio_phone));
                            navigate(LeadsPath)
                        }
                    })
                }
            }
        })
    };

    return (
        <div className="w-full grow px-5 space-y-4 flex flex-col">
            {isLoading && <LoadingMask />}
            <ToastContainer progressStyle={{ "background": "#D4AF37" }} />
            <div className="text-black max-h-full p-5 w-screen md:w-full relative overflow-x-auto bg-white rounded-lg shadow transition-all duration-500 ease-in-out overflow-hidden">
                <div className="text-xl font-bold text-black pl-3">
                    {t("LEAD")}
                </div>
                <div className="pb-5">
                    <Form handleOnSubmit={handleSubmit(onSubmit)}>
                        <div className='w-full text-lg font-semibold p-3'>{t("PERSONAL_INFORMATION")}</div>
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/2'
                            label={t("FIRST_NAME")}
                            name="first_name"
                            isRequired />
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/2'
                            label={t("LAST_NAME")}
                            name="last_name"
                            isRequired />
                        <ReactiveFormInput
                            control={control}
                            type='date'
                            className='md:w-1/3'
                            label={t("DATE_OF_BIRTH")}
                            name="dob"
                            isRequired />
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/3'
                            label={t("EMAIL")}
                            name="email"
                            type='email'
                            isRequired />
                        <ReactiveFormPhoneInput
                            control={control}
                            className='md:w-1/3'
                            name="phone"
                            label={t("PHONE")}
                            isRequired
                        />
                        <ReactiveFormInput
                            control={control}
                            label={t("STREET_ADDRESS")}
                            name={`address`}
                            isRequired
                        />
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/3'
                            label={t("CITY")}
                            name={`city`}
                            isRequired
                        />
                        <ReactiveFormSelect
                            className="md:w-1/3"
                            name={`state`}
                            label={t("STATE")}
                            options={getStates}
                            control={control}
                            isRequired
                        />
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/3'
                            label={t("ZIP_CODE")}
                            name={`zip_code`}
                            isRequired
                        />
                        <div className='w-full text-lg font-semibold p-3'>{t("LEAD_INFORMATION")}</div>
                        <ReactiveFormSelect
                            className="md:w-1/4"
                            name={`status_id`}
                            label={t("STATUS")}
                            options={getLeadStatuses()}
                            control={control}
                        />
                        <ReactiveFormSelect
                            className="md:w-1/4"
                            name={`source_id`}
                            label={t("SOURCE")}
                            options={getSources()}
                            control={control} />
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/4"
                            label={t("REFERRED_BY")}
                            name={`referred_by`}
                        />
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/4"
                            label={t("AMOUNT")}
                            name={`referred_amount`}
                            type='number'
                        />
                        <ReactiveFormRadioButtonText
                            className={"md:w-1/2"}
                            name="start_automation"
                            label={t("START_AUTOMATION_LEAD_MESSAGE")}
                            control={control}
                            options={[{ label: t("YES"), value: "Yes" }, { label: t("NO"), value: "No" }]}
                        />
                        {
                            showAutomation &&
                            <ReactiveFormSelect
                                className="md:w-1/2"
                                name={`automation_id`}
                                label={t("AUTOMATION")}
                                options={getAutomations}
                                control={control}
                            />
                        }
                    </Form>
                </div>
            </div>
            <div className='pb-5 text-right'>
                <Button onClick={handleSubmit(onSubmit)}>
                    <span className='flex items-center font-semibold pr-3'>
                        <div className='w-8 p-1 aspect-square mr-2'><UserPlusIcon /></div>
                        {t('SAVE_FOR_LEADS')}
                    </span>
                </Button>
            </div>
        </div>
    );
};