import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AllClients, Client, ClientResponse, ClientsFilters } from "../../models/Client";
import { clearForm, createClient, createClientFromLead, createClientFromSurvey, deleteClient, fetchClientById, fetchClients, pastPage, updateClient } from "./actions";

interface ClientState {
  clients: AllClients[],
  pageClients: Client[],
  currentClient?: Client,
  isLoading: boolean,
  error: string | null,
  paginatedOptions: {
    page: number,
    pageSize: number,
    totalPages: number,
    totalCount: number
  },
  filters?: ClientsFilters
}

const initialState: ClientState = {
  clients: [],
  pageClients: [],
  isLoading: false,
  error: null,
  paginatedOptions: {
    page: 0,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
  }
}

// slice
const clientsSlice = createSlice({
  name: "clients",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchClients.fulfilled, (state, action: PayloadAction<ClientResponse>) => {
        state.isLoading = false;
        state.clients = state.clients.concat({ pageNumber: action.payload.page, pageClients: action.payload.clients })
        state.pageClients = action.payload.clients;
        state.paginatedOptions.page = action.payload.page;
        state.paginatedOptions.pageSize = action.payload.pageSize;
        state.paginatedOptions.totalPages = action.payload.totalPages;
        state.paginatedOptions.totalCount = action.payload.totalCount;
        state.filters = action.payload.filters
      })
      .addCase(fetchClients.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createClientFromSurvey.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(createClientFromSurvey.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createClientFromSurvey.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createClient.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(createClient.fulfilled, (state, action: PayloadAction<Client[]>) => {
        state.isLoading = false;

        state.clients[0].pageClients.unshift(action.payload[0])
        if(state.clients[0].pageClients.length > state.paginatedOptions.pageSize) {
          state.clients[0].pageClients.pop()
        }
        state.pageClients = state.clients[0].pageClients;
        state.paginatedOptions.page = 0;
        
        /*
        if (state.paginatedOptions.page === state.paginatedOptions.totalPages) {
          if (state.pageClients.length < state.paginatedOptions.pageSize) {
            const newClients = state.pageClients.concat(action.payload);
            state.pageClients = newClients;
            const updatedClients = state.clients;
            updatedClients[state.paginatedOptions.page].pageClients = newClients;
            state.clients = updatedClients;
          } else {
            state.paginatedOptions.totalPages = state.paginatedOptions.totalPages + 1
          }
        }
        */
      })
      .addCase(createClient.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(updateClient.pending, (state, action) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(updateClient.fulfilled, (state, action: PayloadAction<Client>) => {
        state.isLoading = false;
        state.error = null;
        //if (state.paginatedOptions.page === state.paginatedOptions.totalPages) {
        const updatedClient = action.payload;
        const updatedPageClients = state.pageClients.map((client) => {
          return client.id === updatedClient.id ? updatedClient : client
        }
        );
        const updatedClients = state.clients;
        updatedClients[state.paginatedOptions.page].pageClients = updatedPageClients;
        state.clients = updatedClients;
        state.pageClients = updatedPageClients;
        //}
      })
      .addCase(updateClient.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(deleteClient.pending, (state, action) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(deleteClient.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = null;
        const updatedClients = state.clients;
        updatedClients[state.paginatedOptions.page].pageClients = action.payload.clients;
        state.clients = updatedClients;
        state.pageClients = action.payload.clients;
        state.paginatedOptions.page = action.payload.page;
        state.paginatedOptions.pageSize = action.payload.pageSize;
        state.paginatedOptions.totalPages = action.payload.totalPages;
        state.paginatedOptions.totalCount = action.payload.totalCount;
      })
      .addCase(deleteClient.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(createClientFromLead.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        //state.leads = state.clients.concat(action.payload)
      })
      .addCase(createClientFromLead.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createClientFromLead.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(pastPage.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(pastPage.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null
        const result = state.clients.find(x => x.pageNumber === state.paginatedOptions.page - 1);
        if (result) {
          state.pageClients = result.pageClients;
          state.paginatedOptions.page = state.paginatedOptions.page - 1;
        }
      })
      .addCase(fetchClientById.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchClientById.fulfilled, (state, action: PayloadAction<Client>) => {
        state.isLoading = false;
        state.currentClient = action.payload;
      })
      .addCase(fetchClientById.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(clearForm.fulfilled, (state) => {
        state.filters = undefined
      });
  },
});

export default clientsSlice.reducer;