import { FunnelIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import phoneBg from '../../assets/images/phone-bg.png';
import Button from "../../components/Button";
import { Badge } from "../../components/Calls/Badge";
import { CallStatusIcon } from "../../components/Calls/CallStatusIcon";
import { ConversationFilters } from "../../components/Calls/ConversationFilters";
import { PhoneBurner } from "../../components/Calls/PhoneBurner";
import { PhoneBurnerIcon } from "../../components/Calls/PhoneBurnerIcon";
import ClientContactForm from "../../components/ClientContactForm";
import LeadContactForm from "../../components/LeadContactForm";
import { CallStatus, CallStatusLabel } from "../../enums/CallStatus";
import { ConversationType } from "../../enums/ConversationType";
import { CallDirection } from "../../enums/TwilioCalls";
import { TwilioDeviceState } from "../../enums/TwilioDeviceState";
import { Client } from "../../models/Client";
import { ConversationFilter } from "../../models/ConversationFilter";
import { Lead } from "../../models/Lead";
import { TwilioCall } from "../../models/TwilioCall";
import { messageDateFormat } from "../../utils/functions";

interface Props {
    phone: string,
    isCurrentCall: boolean,
    phoneNames: Map<string, any>,
    endCall: () => void;
    muteCall: () => void;
    unmuteCall: () => void;
    makePhoneCall: (phone: string) => void;
    isMute?: boolean;
}
export const Phone = (props: Props) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const twilio = useSelector((state: any) => state.twilio);
    const [selectedCall, setSelectedCall] = useState<TwilioCall>();
    const [filter, setFilter] = useState("");
    const [filters, setFilters] = useState<ConversationFilter>({ selectedTypes: [] });
    const [showFilters, setShowFilters] = useState(false);
    const [isPhoneBurnerOpen, setIsPhoneBurnerOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setIsPhoneBurnerOpen(twilio?.phoneBurner?.remainingList?.length > 0 || twilio?.phoneBurner?.currentCall);
    }, [twilio?.phoneBurner]);

    const selectedClient = useMemo(() => {
        return (phoneNumber) ? props.phoneNames.get(phoneNumber) : null;
    }, [props.phoneNames, phoneNumber]);

    const callsWithType = useMemo(() => {
        return twilio.calls.map((call: TwilioCall) => {
            if (call.direction === CallDirection.INBOUND) {
                const client = twilio.phoneContacts.clients.find((x: Client) => x.phone === call.from || x.phone === call.fromFormatted);
                if (client) {
                    return { ...call, conversationType: ConversationType.CLIENTS, name: client.name, id: client.id }
                }
                const lead = twilio.phoneContacts.leads.find((x: Lead) => x.phone === call.from || x.phone === call.fromFormatted);
                if (lead) {
                    return { ...call, conversationType: ConversationType.LEADS, name: lead.name, id: lead.id }
                }
                return { ...call, conversationType: ConversationType.OTHER, name: call.fromFormatted }
            } else {
                const client = twilio.phoneContacts.clients.find((x: Client) => x.phone === call.to || x.phone === call.toFormatted);
                if (client) {
                    return { ...call, conversationType: ConversationType.CLIENTS, name: client.name, id: client.id }
                }
                const lead = twilio.phoneContacts.leads.find((x: Lead) => x.phone === call.to || x.phone === call.toFormatted);
                if (lead) {
                    return { ...call, conversationType: ConversationType.LEADS, name: lead.name, id: lead.id }
                }
                return { ...call, conversationType: ConversationType.OTHER, name: call.toFormatted }
            }

        })
    }, [twilio.calls, twilio.phoneContacts]);

    useEffect(() => {
        if (twilio?.phoneContacts?.clients.find((x: Client) => x.phone === props.phone)) {
            setSelectedCall({ conversationType: ConversationType.CLIENTS, from: '', to: '', status: CallStatus.COMPLETED, direction: CallDirection.INBOUND, startTime: '' })
        }
        else if (twilio?.phoneContacts?.leads.find((x: Client) => x.phone === props.phone)) {
            setSelectedCall({ conversationType: ConversationType.LEADS, from: '', to: '', status: CallStatus.COMPLETED, direction: CallDirection.INBOUND, startTime: '' })
        }
        setPhoneNumber(props.phone);
    }, [props.phone, props.isCurrentCall, twilio.phoneContacts]);

    const handleSearchChange = (event: any) => {
        setFilter(event.target.value);
    }

    const filteredCalls = useMemo(() => {
        let calls = callsWithType;
        if (filters.selectedTypes.length > 0) {
            calls = filters.selectedTypes.reduce((acc: string | any[], element: string) => {
                const filteredConversations = callsWithType.filter((x: TwilioCall) => `${x.conversationType}` === element);
                return acc.concat(filteredConversations);
            }, []);
        }
        calls = [...calls]?.sort((a: { dateUpdated: string | number | Date; }, b: { dateUpdated: string | number | Date; }) => new Date(b.dateUpdated).getTime() - new Date(a.dateUpdated).getTime());
        if (!filter) {
            // If the filter is an empty string, return all conversations.
            return calls;
        }

        // Convert the filter string to lowercase for case-insensitive comparison.
        const filterLowerCase = filter.toLowerCase();

        return calls.filter((call: any) => {
            const name = call.name.toLowerCase();
            return name.includes(filterLowerCase);
        });
    }, [filter, filters, callsWithType]);

    const handleCallClick = (call: TwilioCall) => {
        setSelectedCall(call);
        const phone = call.direction === CallDirection.INBOUND
            ? call.from
            : call.to;
        setPhoneNumber(phone);
        if (twilio.device && (twilio.twilioDeviceState === TwilioDeviceState.READY || twilio.twilioDeviceState === TwilioDeviceState.CONNECT)) {
            props.makePhoneCall(phone)
        }
    };

    const showOrHideFilters = useCallback(() => {
        setShowFilters(prev => !prev);
    }, []);

    const updateFilterValues = (fil: ConversationFilter) => {
        setFilters(fil);
    };

    const openPhoneBurner = () => {
        setIsPhoneBurnerOpen(!isPhoneBurnerOpen)
    }

    return (
        <div className="w-full grow p-5 flex flex-col">
            <div className="text-black max-h-full w-screen md:w-full relative overflow-x-auto bg-white rounded-lg shadow transition-all duration-500 ease-in-out overflow-hidden">
                {twilio.twilioDeviceState === TwilioDeviceState.READY || twilio.twilioDeviceState === TwilioDeviceState.CONNECT ?
                    <div className="w-full grow flex flex-rowoverflow-hidden" style={{ height: 'calc(100vh - 164px)' }}>
                        <div className={classNames({
                            "overflow-x-auto p-5  lg:w-1/4 lg:border-r md:overflow-auto block": true
                        })}>
                            <div className="w-full flex justify-between flex-wrap">
                                <h2 className="text-md font-semibold">Calls</h2>
                                <div className="flex">
                                    <div className="relative flex justify-center lg:items-center gap-2 cursor-pointer">
                                        <Button isDisabled={!twilio.areTwilioCredentialsValid} iconOnly isSecondary onClick={showOrHideFilters}>
                                            <FunnelIcon className="w-6 text-gold" />
                                        </Button>
                                        {showFilters && <ConversationFilters filters={filters} updateFilterValues={updateFilterValues} />}
                                    </div>
                                    <Button isDisabled={!twilio.areTwilioCredentialsValid} iconOnly isSecondary onClick={openPhoneBurner}>
                                        <PhoneBurnerIcon />
                                    </Button>
                                </div>
                            </div>
                            <div className="w-full flex flex-wrap pb-2">
                                <input
                                    placeholder="Search ..."
                                    className="bg-white opacity-90 py-2 px-4 rounded shadow w-full"
                                    type="text"
                                    onChange={handleSearchChange}
                                    value={filter}
                                />
                            </div>
                            {filteredCalls?.map((call: TwilioCall) => {
                                return <div className={classNames({
                                    "flex flex-row  gap-3 p-3 hover:bg-neutral-200 cursor-pointer shadow-sm hover:bg-gray": true,
                                })}
                                    onClick={() => handleCallClick(call)}
                                    key={call.sid}>
                                    <div className="flex flex-col grow gap-2">
                                        <div className="flex flex-row justify-between">
                                            <div className="flex gap-2">
                                                <div className={"font-semibold"}>
                                                    {call.name}
                                                </div>
                                                <Badge type={call.conversationType} />
                                            </div>
                                            <div className="text-sm font-light text-neutral-500">{messageDateFormat(call.startTime)}</div>
                                        </div>
                                        <div className="flex gap-2">
                                            <CallStatusIcon type={call.direction} status={call.status} />
                                            <span className="text-sm text-neutral-500">{CallStatusLabel(call.status)}</span>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className="flex flex-col relative w-full">
                            {isPhoneBurnerOpen
                                ? <PhoneBurner endCall={props.endCall} />
                                : <>
                                    {selectedCall?.conversationType !== ConversationType.LEADS && selectedCall?.conversationType !== ConversationType.CLIENTS &&
                                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                            <img src={phoneBg} alt="Asureis Logo" className='h-32' />
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                    : <div className="text-l text-center relative inset-y-2/4" style={{ height: 'calc(100vh - 164px)' }}>{t("TWILIO_CONNECTION_ERROR")}</div>}
            </div>
        </div>
    )
}
