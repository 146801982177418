import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormUploadFile } from "./FormUploadFile"

interface Props {
    name: string
    value?: string | number
    label: string
    className?: string
    placeholder?: string
    isDisabled?: boolean
    handleOnChange?: (event: any) => void
    handleOnDrop?: (event: any) => void
    control: any
    isRequired?: boolean
}

export const ReactiveFormUploadFile = (props: Props) => {
    const { t } = useTranslation();

    const fileChange = (event: any) => {
        props.handleOnChange!(event.target.files);
    }

    const dropFiles = (event: any) => {
        event.preventDefault();
        props.handleOnDrop!(event.dataTransfer.files);
    }

    return (
        <Controller
            name={props.name}
            control={props.control}
            rules={{ required: props.isRequired ?? false }}
            render={({
                field: { onChange, value },
                fieldState: { error },
            }) => {
                return (<FormUploadFile
                    label={props.label}
                    name={props.name}
                    value={value}
                    handleOnChange={fileChange}
                    handleOnDrop={dropFiles}
                    error={error ? error.message ? error.message : t("FIELD_REQUIRED") : ""}
                    className={props.className}
                    placeholder={props.placeholder} />)
            }} />
    )
}