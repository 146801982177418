import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { MetadataItem } from "../../models/Metadata";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { fetchCommissionRuleTypes } from "../../state/commissions/action";
import { AppDispatch } from '../../state/store';
import { t } from "i18next";
import FilteredTable from "../../components/FilteredTable";
import { dateFormat } from "../../utils/functions";
import {
    useNavigate,
} from "react-router-dom";
import Button from "../../components/Button";
import { CurrencyDollarIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { CommissionRuleTypesMetadataView } from "./Views/CommissionRuleTypesMetadataView";
import { setSelectedMenuItem } from "../../state/metadata/slice";

export const Metadata = () => {
    const menuItems = useSelector((state: any) => state.menuItems.items);
    const isLoading = useSelector((state: any) => state.menuItems.isLoading);
    const error = useSelector((state: any) => state.menuItems.error);
    const selectedMenuItem = useSelector((state: any) => state.menuItems.selectedItem);
    const dispatch = useDispatch<AppDispatch>();

    const onItemClick = (item: MetadataItem) => {

        if (selectedMenuItem?.id !== item.id) {
            dispatch(setSelectedMenuItem(item))
        }
    };

    return (
        <div className="w-full grow px-5 space-y-4 flex flex-col">
            <ToastContainer progressStyle={{ "background": "#D4AF37" }} />
            <div className="text-black max-h-full p-5 w-screen md:w-full relative overflow-x-auto bg-white rounded-lg shadow transition-all duration-500 ease-in-out overflow-hidden">
                <div className="w-full grow flex flex-row overflow-hidden" style={{ height: 'calc(100vh - 164px)' }}>
                    <div className="overflow-x-auto lg:w-1/4 lg:border-r md:overflow-auto pr-5"> {
                        menuItems.map((item: MetadataItem) => 
                            <div className={
                                    classNames({"flex flex-row  gap-3 p-3 cursor-pointer shadow-sm hover:bg-gray-100": true,
                                        "text-black border-gold border-2 border-l-0 border-r-0 border-t-0": item.id === selectedMenuItem?.id,
                                     })
                                } 
                                onClick={() => onItemClick(item)}>
                                {t(item.name)}
                            </div>)}
                    </div>

                    <div className="w-full">
                        {selectedMenuItem?.id == 1 && <CommissionRuleTypesMetadataView dataLoadApiUrl={selectedMenuItem?.data_load_api_url}/>}
                    </div>
                </div>
            </div>
        </div>
    )
}