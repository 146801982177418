import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';
import { axiosClient } from "../axiosClient";
import { DownloadUrl, SignedUrl } from "../../models/Document";

export const fetchAttachmentsByClientId = createAsyncThunk("attachmentsByClientId", async (params: {clientId: number}) => {
    const response: AxiosResponse<any[]> = await (await axiosClient()).get(`/clients/${params.clientId}/attachments`);
    return response.data
});

export const getFileSignedUrl = createAsyncThunk("getSignedUrl", async (params: {fileName: string}) => {
    const response: AxiosResponse<SignedUrl> = await (await axiosClient()).get(`storage/signed-url?filename=${params.fileName}&location=0`);
    return response.data;
});

export const getFileDownloadUrl = createAsyncThunk("getDownloadUrl", async (params: {fileName: string}) => {
    const response: AxiosResponse<DownloadUrl> = await (await axiosClient()).get(`storage/download-url?filename=${params.fileName}&location=0`);
    return response.data;
});