import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface Props {
    name: string;
    value: string;
    label: string;
    options: { value: string; label: string }[];
    type?: string;
    className?: string;
    placeholder?: string;
    error?: string;
    isDisabled?: boolean;
    isRequired?: boolean;
    onBlur?: (value: any) => void;
    onChange: (value: string) => void;
}

export const FormSearchableSelect = (props: Props) => {
    const { t } = useTranslation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<any>(null);

    // Filter options based on the search text
    const filteredOptions = useMemo(() => {
        return props.options.filter((option) => option.label.toLowerCase().includes(props.value.toLowerCase()));
    }, [props.options, props.value]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsDropdownOpen(true);
        props.onChange(e.target.value);
    };

    const handleOptionClick = (option: { value: string; label: string }) => {
        props.onChange(option.value);
        setIsDropdownOpen(false);
    };

    const handleClickOutside = (e: Event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div ref={dropdownRef} className={"relative w-full px-3 " + props.className ?? ""}>
            <label className="block tracking-wide text-gray-700 text-sm mb-2">
                {props.label}
            </label>
            <input
                id="searchableInput"
                type="text"
                placeholder={t("SEARCH_&_SELECT")}
                value={props.value}
                onChange={handleInputChange}
                onFocus={() => setIsDropdownOpen(true)}
                onBlur={(e) => {
                    setTimeout(() => {
                        let input:any = document.getElementById("searchableInput")
                        props.onBlur && props.onBlur(input.value)
                    }, 100)
                    
                }}
                className={classNames({
                    "appearance-none block w-full border border-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-blue": true,
                    "border-red-500": props.error,
                    "bg-slate-100": props.isDisabled == true
                })}
            />

            {isDropdownOpen && (
                <div className="absolute top-10 left-0 bg-white border border-gray-300 rounded mt-9 w-full z-[20]">
                    {filteredOptions.map((option) => (
                        <div
                            key={option.value}
                            className="p-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};