
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelect } from "./FormSelect";
import classNames from "classnames";

interface Props {
    name: string
    value?: string | number
    label: string
    handleOnChange?: (event: any) => void
    options: {value: string, label: string}[]
    type?: string
    className?: string
    placeholder?: string
    error?: string
    isDisabled?: boolean

    control: any
    isRequired?: boolean
}

export const ReactiveFormSelect = (props: Props) => {
    const { t } = useTranslation();
    return (
        <Controller
            name={props.name}
            control={props.control}
            rules={{ required: props.isRequired ?? false }}
            render={({
                field: { onChange, value },
                fieldState: { error },
            }) => {
                return (<FormSelect
                    options={props.options}
                    label={props.label}
                    name={props.name}
                    selectedValue={value}
                    error={error ? error.message ? error.message : t("FIELD_REQUIRED") : ""}
                    handleOnChange={(e) => {
                        onChange(e)
                        props.handleOnChange && props.handleOnChange(e)
                    }}
                    className={props.className}
                    placeholder={props.placeholder} 
                    isDisabled={props.isDisabled}/>)
            }} />
    )
}