import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clearForm, clearPolicySells, fetchAllPolicySells, fetchPolicySells, fetchTotalPolicySells, pastPage } from "./actions";
import { AllPolicySell, PolicyAgentSummary, PolicySell, PolicySellDates, PolicySellFilters, PolicySellResponse } from "../../models/PolicySell";

interface PolicySellsState {
  policySells: AllPolicySell[],
  pagePolicySells: PolicySell[],
  restPolicySells: PolicySell[],
  isLoading: boolean,
  error: string | null,
  paginatedOptions: {
    page: number,
    pageSize: number,
    totalPages: number,
    totalCount: number
  },
  dates: PolicySellDates,
  filters?: PolicySellFilters,
  dailyTotalPoliciesSell?: any,
  totalMembersInsured?: number,
  policyAgentsSummary?: PolicyAgentSummary[];
}

const initialState: PolicySellsState = {
  policySells  : [],
  pagePolicySells: [],
  restPolicySells: [],
  isLoading: false,
  error: null,
  paginatedOptions: {
    page: 0,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
  },
  dates: {},
  policyAgentsSummary: []
}

// slice
const policySellsSlice = createSlice({
    name: "policyLeadboard",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchPolicySells.pending, (state) => {
          state.isLoading = true;
          state.error = null
        })
        .addCase(fetchPolicySells.fulfilled, (state, action: PayloadAction<PolicySellResponse>) => {
          state.isLoading = false;
          state.error = null

          const actualPage = state.policySells.findIndex(x => x.pageNumber === action.payload.page);
          if(actualPage == -1) {
            state.policySells = state.policySells.concat({ pageNumber: action.payload.page, pagePolicies: action.payload.policies});
          } else {
            state.policySells.splice(actualPage, 1);
            state.policySells = state.policySells.concat({ pageNumber: action.payload.page, pagePolicies: action.payload.policies});
          }

          state.pagePolicySells = action.payload.policies;
          state.paginatedOptions.page = action.payload.page;
          state.paginatedOptions.pageSize = action.payload.pageSize;
          state.paginatedOptions.totalPages = action.payload.totalPages;
          state.paginatedOptions.totalCount = action.payload.totalCount;
          state.totalMembersInsured = action.payload.totalMembersInsured;
          state.policyAgentsSummary = action.payload.policyAgentsSummary;
          state.filters = action.payload.filters;
          state.dates = action.payload.dates;
        })
        .addCase(fetchPolicySells.rejected, (state, action: any) => {
          state.isLoading = false;
          state.error = action.payload;
        })
        .addCase(pastPage.pending, (state) => {
          state.isLoading = true;
          state.error = null
        })
        .addCase(pastPage.fulfilled, (state) => {
          state.isLoading = false;
          state.error = null
          const result = state.policySells.find(x => x.pageNumber === state.paginatedOptions.page - 1);
          if (result) {
            state.pagePolicySells = result.pagePolicies;
            state.paginatedOptions.page = state.paginatedOptions.page - 1;
          }
        })
        .addCase(clearPolicySells.fulfilled, (state) => {
          state.policySells = [];
        })
        .addCase(fetchTotalPolicySells.fulfilled, (state, action: PayloadAction<any>) => {
          state.dailyTotalPoliciesSell = action.payload;
        })
        .addCase(clearForm.fulfilled, (state) => {
          state.filters = undefined
        });
    },
});
  
export default policySellsSlice.reducer;