import { Bars3Icon, ClipboardDocumentListIcon, DeviceTabletIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AgencyPath, AutomationPath, ClientsPath, CommissionsPath, ConversationsPath, DashboardPath, DocumentsPath, LeadsPath, MetadataPath, NewAutomationPath, NewClientPath, NewLeadPath, NewUserPath, PhonePath, PolicySellsReportPath, ProfilePath, UpdateAutomationPath, UpdateClientPath, UpdateLeadPath, UpdateUserPath, UsersPath } from "../constants/Routes";
import { RoleEnum, RoleLabel } from "../enums/Role";
import { Keypad } from "./Calls/Keypad";
import Button from "./Button";
import { TwilioDeviceState } from "../enums/TwilioDeviceState";

type Props = {
  onMenuButtonClick(): void;
};

const Navbar = (props: Props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const twilio = useSelector((state: any) => state.twilio);
  const navigate = useNavigate();
  const [isKeypadOpen, setIsKeypadOpen] = useState(false);

  const openKeypad = useCallback(() => {
    setIsKeypadOpen(prevValue => !prevValue);
  }, []);

  const getTitle = useMemo(() => {
    switch (location.pathname) {
      case ClientsPath: return t("CLIENTS");
      case NewClientPath: return t("NEW_CLIENT");
      case ConversationsPath: return t("CONVERSATION");
      case PhonePath: return t("PHONE")
      case LeadsPath: return t("LEADS");
      case NewLeadPath: return t("NEW_LEAD");
      case DocumentsPath: return t("DOCUMENTS");
      case UsersPath: return t("USERS");
      case NewUserPath: return t("NEW_USER");
      case ProfilePath: return t("PROFILE");
      case UpdateClientPath: return t("UPDATE_CLIENT");
      case UpdateLeadPath: return t("UPDATE_LEAD");
      case UpdateUserPath: return t("UPDATE_USER");
      case AutomationPath: return t("LEAD_AUTOMATIONS");
      case NewAutomationPath: return t("NEW_LEAD_AUTOMATION");
      case UpdateAutomationPath: return t("UPDATE_LEAD_AUTOMATION");
      case AgencyPath: return t("AGENCY");
      case DashboardPath: return t("DASHBOARD");
      case MetadataPath: return t("METADATA");
      case CommissionsPath: return t("COMMISSIONS");
      case PolicySellsReportPath: return t("POLICY_SELLS_REPORT");
    }
  }, [location, t])

  return (
    <nav
      className={classNames({
        "bg-white text-zinc-500": true, // colors
        "flex items-center": true, // layout
        "w-screen md:w-full sticky z-10 px-4 shadow-sm h-[73px] top-0 ": true, //positioning & styling
      })}
    >
      <div className="font-bold text-lg">{getTitle}</div>
      <div className="flex-grow"></div>
      <div className="relative flex lg:justify-center lg:items-center gap-2 mr-4 cursor-pointer">
        <Button isDisabled={twilio.twilioDeviceState !== TwilioDeviceState.READY} iconOnly onClick={openKeypad} isTerciary>
          <div className='w-6 aspect-square'><DeviceTabletIcon /></div>
        </Button>
        {isKeypadOpen && <Keypad closeKeypad={openKeypad} />}
      </div>
      <div className="md:flex flex-row items-center gap-2 mr-2 cursor-pointer" onClick={() => navigate(ProfilePath)}>
        <div className="hidden md:block">
          <div className="text-end text-blue font-medium">{`${currentUser?.first_name ?? ""} ${currentUser?.last_name ?? ""}`}</div>
          <div className="text-end">{RoleLabel(currentUser?.role_id ?? "")}</div>
        </div>
        <div className="relative flex justify-center items-center">
          <UserCircleIcon className="h-8  aspect-square" />
        </div>

      </div>
      <button className="md:hidden" onClick={props.onMenuButtonClick}>
        <Bars3Icon className="h-6 w-6" />
      </button>
    </nav>
  );
};
export default Navbar;

