import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchPoliciesLeadboard } from "./actions";

interface PolicyLeadboardState {
    policiesLeadboard: [],
    isLoading: boolean,
    error: string | null
}

const initialState: PolicyLeadboardState = {
    policiesLeadboard  : [],
    isLoading: false,
    error: null
}

// slice
const policyLeadboardSlice = createSlice({
    name: "policyLeadboard",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchPoliciesLeadboard.pending, (state) => {
          state.isLoading = true;
          state.error = null
        })
        .addCase(fetchPoliciesLeadboard.fulfilled, (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.policiesLeadboard = action.payload;
          state.error = null
        })
        .addCase(fetchPoliciesLeadboard.rejected, (state, action: any) => {
          state.isLoading = false;
          state.error = action.payload;
        });
    },
});
  
export default policyLeadboardSlice.reducer;