import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchMetadataMenuItems } from "./action";
import { MetadataItem } from "../../models/Metadata";

interface MetadataItemState {
    items: MetadataItem[],
    selectedItem: MetadataItem | null,
    isLoading: boolean,
    error: string | null,
}

const initialState: MetadataItemState = {
    items: [],
    selectedItem: null,
    isLoading: false,
    error: null,
}

// slice
const metadataMenuItemsSlice = createSlice({
    name: "menuItems",
    initialState: initialState,
    reducers: {
      setSelectedMenuItem(state, action: PayloadAction<MetadataItem>) {
        state.selectedItem = action.payload;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchMetadataMenuItems.pending, (state) => {
          state.isLoading = true;
          state.items = [];
          state.error = null
        })
        .addCase(fetchMetadataMenuItems.fulfilled, (state, action: PayloadAction<MetadataItem[]>) => {
          state.isLoading = false;
          state.items = action.payload;
          state.error = null
        })
        .addCase(fetchMetadataMenuItems.rejected, (state, action: any) => {
          state.isLoading = false;
          state.items = [];
          state.error = action.payload;
        });
    },
});
  
export const { setSelectedMenuItem } = metadataMenuItemsSlice.actions;

export default metadataMenuItemsSlice.reducer;