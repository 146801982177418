import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchAttachmentsByClientId } from "./actions";

interface AttachmentState {
    attachments: any[],
    isLoading: boolean,
    error: string | null
}
  
const initialState: AttachmentState = {
    attachments: [],
    isLoading: false,
    error: null
}

// slice
const attachmentsSlice = createSlice({
    name: "attachments",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchAttachmentsByClientId.pending, (state) => {
          state.isLoading = true;
          state.error = null
        })
        .addCase(fetchAttachmentsByClientId.fulfilled, (state, action: PayloadAction<any[]>) => {
          state.isLoading = false;
          state.attachments = action.payload;
        })
        .addCase(fetchAttachmentsByClientId.rejected, (state, action: any) => {
          state.isLoading = false;
          state.error = action.payload;
        });
    },
});
  
export default attachmentsSlice.reducer;